import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Paging from "../components/paging"
import InterviewTag from "../components/interview-tag"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function RemarkInterviewlist({ data, pageContext }) {
  const { currentPage, numPages, category } = pageContext
  const posts = data.allMdx.nodes
  const linkUrl = `/${category}`
  const { categorys, categoryHeadings } = useSiteMetadata()
  const categoryName = categorys[category]
  return (
    <Layout slug={`/${category}/`} category={category} wrapperAddClassName="u-bgc-gray" {...data.meta.nodes[0]}>
      <div className="c-page-title">
        <span>{categoryHeadings[category]}</span>
        <h1>{categoryName}</h1>
      </div>
      <div className="p-interview-items">
        {posts.map((node) => (
          <div className="c-interview-item">
            <Link to={node.fields.slug}>
              <InterviewTag.CardImage category={category} basename={node.fields.basename} title={node.frontmatter.title} />
              <div className="c-interview-item-data">
                <h2>{node.frontmatter.title}</h2>
                {node.frontmatter.tags && (
                <ul>
                  {node.frontmatter.tags.map((tag) => (
                    <li>#{tag}</li>
                  ))}
                </ul>
                )}
                <InterviewTag.Interviewee category={category} organization={node.frontmatter.organization} position={node.frontmatter.position} interviewee={node.frontmatter.interviewee} />
              </div>
            </Link>
          </div>
        ))}
        <Paging path={linkUrl} currentPage={currentPage} numPages={numPages} />
      </div>
    </Layout>
  )
}

export const query = graphql`
query($category: String!, $metaSlug: String!, $skip: Int!, $limit: Int!) {
  meta: allMeta(filter: {slug: {eq: $metaSlug}}) {
    nodes {
      title
      description
      keywords
    }
  }
  allMdx(
    sort: {fields: [frontmatter___date, fields___basename], order: [DESC, DESC]}
    filter: {fields: {category: {eq: $category}}}
    limit: $limit
    skip: $skip
  ) {
      nodes {
        fields {
          slug
          basename
        }
        frontmatter {
          date
          title
          tags
          description
          organization
          position
          interviewee
        }
      }
    }
  }
`